// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";


.pdfView > body{
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  text-align: center;
  color: #777;
}
.pdfView > h1 {
  font-weight: 300;
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: #000;
}
.pdfView > h3 {
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 20px;
  font-style: italic;
  color: #555;
}

.pdfView > a {
  color: #06f;
}

.invoice-box {
max-width: 800px;
margin: auto;
padding: 30px;
border: 1px solid #eee;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
font-size: 16px;
line-height: 24px;
font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
color: #555;
}
  
.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
  border-collapse: collapse;
}

.invoice-box table tr.top table td.title {
  padding-right: 30px;
  color: #333;
}

.invoice-box table tr.top table td.pdfDate {
  color: #333;
  text-align: right;
}

.invoice-box table tr.top table td.header {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}
.invoice-box table tr.pdfHeader td:nth-child(2) {
  text-align: right;
}
.invoice-box table tr.heading td:nth-child(2) {
  text-align: right;
}
.invoice-box table tr.item td:nth-child(2) {
  text-align: right;
}
  .invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
  }

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  };

  .invoice-box table td.center {
      text-align: center;
    };
  .invoice-box table td.left {
      text-align: left;
  };

  .invoice-box table tr.ledgerHeading td {
    font-size: 12px;
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    };

  .invoice-box table tr.details td {
    padding-bottom: 20px;
  }
      
  .invoice-box table tr.ledgerItem td {
    font-size: 12px;
    border-bottom: 1px solid #eee;
  };

  .invoice-box table tr {
    page-break-inside: avoid; 
    page-break-after: auto;
  }
  .invoice-box table {
    page-break-inside: auto;
  }
.invoice-box table tr.item td {
border-bottom: 1px solid #eee;
}
.invoice-box table tr.item.last td {
border-bottom: none;
}	
.invoice-box table tr.total td:nth-child(2) {
border-top: 2px solid #eee;
font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
  width: 100%;
  display: block;
  text-align: center;
  }
  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}
