.moves-table-container {
  margin-top: 20px;
  width: 100%;
}

.moveins-table-p {
  font-size: 0.7rem;
  margin: 0;
}

.moves-bulkactionbar {
  margin: 30px 0px;
  padding-left: 20px;
}

.moves-bulkaction-button-group {
  text-align: center;
}

.moves-bulkaction-button-group > button {
  margin-right: 5px;
  margin-top: 10px;
}

.moves-legendbox-bullet {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.loading-cursor:hover {
  cursor: wait;
}
